<template>
  <page-template>
    <main-block>
      <block-head title="Export Leads" :description="$t('Export leads to a CSV file')">
        <block-tools>
          <li>
            <nk-button type="primary" v-on:click="newExport.modalOpen=true">
              <nio-icon icon="ni-plus"></nio-icon> {{$t('New Export')}}
            </nk-button>
          </li>
        </block-tools>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                        <option value="">{{ $t('Bulk Action') }}</option>
                        <option value="delete">{{ $t('Delete') }}</option>
                      </select>
                    </div>
                    <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">{{ $t('Apply') }}</nk-button>
                          </span>
                      <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button
                                   v-on:click="getExportRecords(true)">
                          <nio-icon icon="ni-reload"></nio-icon>
                        </nk-button>
                      </li>
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: order === 'asc'}"><a href="javascript:void(0)" v-on:click="[order='asc', getExportRecords(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: order === 'desc'}"><a href="javascript:void(0)" v-on:click="[order='desc', getExportRecords(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <ion-list v-if="$isHybridPlatform">
                  <template v-for="item in exportData" :key="'ex_i'+item.id">
                    <ion-item button
                              v-on:click="recordActionSheet(item)"
                              lines="full" style="--border-color:#e3eaf5;--padding-bottom: 8px;--padding-top: 8px;">
                      <div>
                        <ion-label class="fw-500" color="dark">{{ item.created_at }}</ion-label>
                        <ion-text color="soft" class="medium d-block">{{ item.filename }}</ion-text>
                        <ion-text color="soft" class="medium d-block"
                                  v-if="item.from_date || item.to_date">
                          {{ item.from_date ? ((!item.to_date ? $t('from')+' ' : '')+item.from_date) : '' }}
                          {{ item.to_date ? ((!item.from_date ? $t('up to')+' ' : ' - ')+item.to_date) : '' }}
                        </ion-text>
                      </div>
                      <ion-badge color="primary" slot="end">{{ item.status === 0 ? $t('Pending') : item.leads_count+' '+$t('leads') }}</ion-badge>
                    </ion-item>
                  </template>
                </ion-list>
                <special-table v-else>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="csv_r_select_all" v-model="selectAll"></b-check-box>
                    </table-col>
                    <table-col break-point="md">
                      <span class="sub-text">ID</span>
                    </table-col>
                    <table-col>
                      <span class="sub-text">{{ $t('Filename')}}</span>
                    </table-col>
                    <table-col break-point="mb">{{ $t('Leads Count') }}</table-col>
                    <table-col break-point="mb">{{ $t('Date Filter') }}</table-col>
                    <table-col break-point="mb">{{ $t('Created At') }}</table-col>
                    <table-col break-point="mb">{{ $t('Status') }}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="item in exportData" :key="'ex_t_i'+item.id">
                    <table-col has-check-box>
                      <b-check-box :id="'exp_s'+item.id" v-model="item.selected" :key="'exp_cb'+item.id"></b-check-box>
                    </table-col>
                    <table-col break-point="md">{{ item.id }}</table-col>
                    <table-col>{{ item.filename || '--' }}</table-col>
                    <table-col>{{ item.status === 0 ? '--' : item.leads_count }}</table-col>
                    <table-col break-point="mb">
                      {{ item.from_date ? ((!item.to_date ? $t('from')+' ' : '')+item.from_date) : '' }}
                      {{ item.to_date ? ((!item.from_date ? $t('up to')+' ' : ' - ')+item.to_date) : '' }}
                    </table-col>
                    <table-col break-point="mb">{{ item.created_at }}</table-col>
                    <table-col break-point="mb">{{ $t(item.status === 0 ? 'Pending' : 'Ready') }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-download" v-if="parseInt(item.status) === 1"
                                       :text="$t('Download')" v-on:click="downloadFile(item.id)"></list-item>
                            <list-item icon="ni-trash-fill" :text="$t('Delete')" v-on:click="deleteRecords([item])"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > 10">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <leads-csv-export-modal
        :modal-open="newExport.modalOpen"
        @modal-close="newExport.modalOpen=false" @export-initiated="getExportRecords(true)"></leads-csv-export-modal>
  </page-template>
</template>

<script>
import {
  actionSheetController, IonSpinner, onIonViewWillEnter, IonList,
  IonItem,
  IonLabel,
  IonText, IonBadge, loadingController, toastController, isPlatform, alertController,
} from '@ionic/vue';
import {defineComponent, reactive, ref, watch} from 'vue';
import axios from "@/libs/axios"
import {Card, CardHeader, CardTools} from "@core/components/cards";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import {DropDown,DropDownList} from "@core/components";

import Pagination from "v-pagination-3"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import {ListItem} from "@core/components/dropdown-list";
import {useI18n} from "vue-i18n";
import {BlockContent, BlockHead, BlockTools, MainBlock} from "@core/layouts";
import 'flatpickr/dist/flatpickr.css';
import {BCheckBox} from "@core/components/bp-form";
import useIonicComps from '@core/IonComponents'
import LeadsCsvExportModal from "@/views/leads-export/components/LeadsCsvExportModal";
import useCommonFunc from "@core/comp-functions/common";
import PageTemplate from "@core/layouts/page/PageTemplate";


export default defineComponent({
  components: {
    PageTemplate,
    LeadsCsvExportModal,
    BlockTools,
    ListItem,
    DropDownList,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardTools,
    BlockHead,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonSpinner,
    Pagination,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonBadge,
  },
  setup() {

    const isDesktop = isPlatform('desktop')
    const {t} = useI18n()
    let order         = ref('desc')
    let exportData = ref([])
    let loadingData = ref(false)
    let selectAll    = ref(false)
    const{IonToast} = useIonicComps()
    const {getSelectedRecordsForBulkAction} = useCommonFunc()
    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getExportRecords(true)
    }
    let newExport = reactive({
      modalOpen: false,
    })

    const getExportRecords = async (reset) => {

      loadingData.value = true;
      axios.post('/api/leads/csv_export/get_csv_list', {
        page: pagination.page,
        order: order.value
      })
          .then( (resp) => {
            if(reset){
              exportData.value.splice(0);
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              exportData.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            loadingData.value = false
          })
    }

    onIonViewWillEnter(()=> {
      getExportRecords(true)
    })

    watch(selectAll, (n) => {
      for(let x in exportData.value){
        exportData.value[x].selected = n
      }
    })

    const deleteRecords = async ( _records ) => {

      let iAlert = await alertController.create({
        cssClass: isDesktop ? 'alert-web' : '',
        header: t(`Delete record${(_records.length > 1 ? 's' : '')}`),
        message: t('action_confirmation', {action: t('delete'), count: _records.length, type: t('record' + (_records.length > 1 ? 's': '') )}),
        buttons: [
          {
            text: t('Cancel'),
            cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
          },
          {
            cssClass: isDesktop ? 'alert-btn-danger' : 'text-danger',
            text: t('Delete'), handler: async () => {
              let ids = []
              for(let x in _records){
                ids.push( _records[x].id)
              }
              const loading = await loadingController.create({message: t('Please wait...')})
              await loading.present();
              const toast   = await toastController.create({position: "top", duration: 3000})
              axios.delete('/api/leads/csv_export/delete_csv', {data:{ids: JSON.stringify(ids)}})
                  .then( resp => {
                    toast.message = resp.data.success ? t('Thank you! action completed') : resp.data.message
                    toast.color   = resp.data.success ? "primary" : "danger"
                    toast.present()
                    if(resp.data.success){
                      // Remove the deleted entries from the records list
                      for(let x in _records){
                        for(let y in exportData.value){
                          if(exportData.value[y].id === _records[x].id){
                            exportData.value.splice(y,1)
                            break;
                          }
                        }
                      }
                      // All deleted, no entry left in the advisor array? and previous pages exists
                      if( !exportData.value.length && pagination.page > 1 ){
                        // Deduct from total records value
                        pagination.totalRecords -= _records.length
                        // Goto previous page
                        pagination.page--
                        getExportRecords(true)
                      }
                    }
                  })
                  .catch(error => {
                    toast.message = t('Error')+': '+error.response.status+' '+error.response.statusText
                    toast.color = "danger"
                    toast.present()
                  })
                  .then( () => loading.dismiss())
            }
          },
        ]
      })
      await iAlert.present()

    }
    const downloadFile = async (id)=>{
      const l = await loadingController.create({message: t('Please wait...')})
      await l.present()
      axios.get('/api/leads/csv_export/get_csv_link/'+id)
          .then( resp => {
            if(resp.data.success){
              window.location.href = process.env.VUE_APP_API_ENDPOINT+'api/download_leads_csv/'+resp.data.data.token;
            }
            else{
              IonToast({message: t('Error')+': '+resp.data.message, position: 'top', color: 'danger', duration: 5000})
            }
          })
          .catch(error => {
            IonToast({message: t('Error')+': '+error.response.status+' '+error.response.statusText, position: 'top', color: 'danger', duration: 5000})
          })
          .then( () => l.dismiss())
    }

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let list = getSelectedRecordsForBulkAction(exportData.value)
      if( !list.length ){
        return false
      }
      if( bulkSelectedAction.value === 'delete' ){
        await deleteRecords(list)
      }
    }

    const recordActionSheet = async (record) => {
      let btns = [
        {
          text: t('Delete'),
          role: 'destructive',
          handler:()=> deleteRecords([record])
        }
      ]
      if(record.status === 1){
        btns.unshift({
          text: t('Download'),
          handler: ()=> downloadFile(record.id)
        })
      }
      const actionSheet = await actionSheetController.create({
        header: t('Choose an action'),
        buttons: btns,
      });
      await actionSheet.present();
    };

    return {
      bulkAction,
      bulkSelectedAction,
      deleteRecords,
      downloadFile,
      exportData,
      getExportRecords,
      loadingData,
      newExport,
      order,
      pagination,
      paginationCallback,
      recordActionSheet,
      selectAll,
    }
  }
});

</script>
