<template>
  <ion-modal
      :is-open="modalOpen"
      @didDismiss="closeModal"
      @didPresent="F_getCSVExportProfiles()"
      :css-class="isDesktop ? 'modal-web': ''">
    <i-modal :title="$t('Start new export')">
      <template v-if="!selectedLeads.length">
        <form-group>
          <row>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="f_name">{{ $t('From Date') }}</label>
              <vue-flatpickr v-model="newExport.filters.from_date" id="d_range_f" class="form-control" :config="vueFlatPickerConfig"></vue-flatpickr>
              <form-note>{{ $t('Export leads received after this date')}}</form-note>
            </column>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="l_name">{{ $t('To Date') }}</label>
              <vue-flatpickr v-model="newExport.filters.to_date" id="d_range_f" class="form-control" :config="vueFlatPickerConfig"></vue-flatpickr>
              <form-note>{{ $t('Export leads received before this date')}}</form-note>
            </column>
          </row>
        </form-group>
        <form-group v-if="['is-advisor', 'is-company'].includes(userRole)">
          <label class="form-label d-block">{{ $t('What to include!') }}</label>
          <div>
            <b-check-box id="l_exp_inc_leads" v-model="newExport.filters.include_leads"
                         :label="$t('Leads')"></b-check-box>
          </div>
          <div>
            <b-check-box id="l_exp_inc_appointments" v-model="newExport.filters.include_appointments"
                         :label="$t('Appointments')"></b-check-box>
          </div>
          <div v-if="userRole === 'is-advisor'">
            <b-check-box id="l_exp_inc_archived" v-model="newExport.filters.include_archived"
                         :label="$t('Archived leads')"></b-check-box>
          </div>
        </form-group>
      </template>
      <div v-else class="mb-2 text-primary">
        {{ $t('export_leads_csv_popup.selected_leads_count', {count: selectedLeads.length}) }}
      </div>
      <form-group v-if="leadsCSV_ExportProfiles.length">
        <label class="form-label d-block">{{ $t('Export profile')}}</label>
        <form-note class="mb-2">{{ $t('export_leads_csv_popup.profile_explanation')}}</form-note>
        <template v-if="newExport.export_profile !== 'custom'">
          <div v-for="(x,index) in leadsCSV_ExportProfiles" :key="'l_csv_prof_'+x.id" class="mb-2">
            <b-radio name="l_exp_l_prof" :id="'l_exp_l_prof'+x.id"
                     :value="x.id"
                     :label="'Profile '+(index+1)" v-model="newExport.export_profile">
              <template v-slot:label>
                <div>{{ $t('Profile')+' '+(index+1)}}</div>
                <span class="text-muted">{{ $t('Included fields/data')}}: </span>
                <form-note>
                <span v-for="(j, ji) in x.included_fields" :key="'lep_'+x.id+'_if_'+j">
                {{ $t( leadsCSV_ExportColumns[j] || '' ) }}{{ ((ji+2) > x.included_fields.length ? '.' : ', ') }}
              </span>
                </form-note>
              </template>
            </b-radio>
          </div>
          <b-radio name="l_exp_l_prof" id="l_exp_l_prof_custom"
                   value="custom"
                   label="Custom Fields" v-model="newExport.export_profile"></b-radio>
        </template>
        <div v-else class="mb-2">
          <ion-item button lines="none" color="light"
                    v-on:click="newExport.export_profile=''"
                    class="rounded text-muted medium">{{ $t('export_leads_csv_popup.view_number_of_profiles', {number: leadsCSV_ExportProfiles.length})}}</ion-item>
        </div>
      </form-group>
      <form-group v-if="!leadsCSV_ExportProfiles.length || newExport.export_profile === 'custom'">
        <label class="form-label d-block">{{ $t('Which fields/data to export!')}}</label>
        <div class="d-flex">
          <a href="javascript:void(0)" class="mr-4" v-on:click="selectAllExportColumns">{{ $t('Select all') }}</a>
          <a href="javascript:void(0)" v-on:click="newExport.includedFields.splice(0)">{{ $t('Deselect all') }}</a>
        </div>
        <div v-for="(x,index) in leadsCSV_ExportColumns" :key="'l_exp_w_col'+index">
          <div class="custom-control custom-checkbox custom-control-sm">
            <input type="checkbox" class="custom-control-input"
                   :id="'l_exp_inc_col_'+index"
                   :value="index"
                   v-model="newExport.includedFields">
            <label class="custom-control-label" :for="'l_exp_inc_col_'+index">{{ $t(x)}}</label>
          </div>
        </div>
      </form-group>
      <form-group v-if="!selectedLeads.length">
        <alert color="info">{{ $t('export_leads_csv_popup.if_no_lead_match_notice') }}</alert>
      </form-group>
      <template v-slot:footer>
        <nk-button type="primary" class="mr-2" v-on:click="startNewExport">{{ $t('Export')}}</nk-button>
        <a href="javascript:void(0);" class="link link-light" v-on:click="closeModal">{{ $t('Cancel') }}</a>
      </template>
    </i-modal>
  </ion-modal>
</template>

<script>
import {IonItem, IonModal, isPlatform, loadingController, modalController} from '@ionic/vue';
import IModal from "@core/components/ion-modal/IModal";
import {Column, FormGroup, Row} from "@core/layouts";
import {BCheckBox, BRadio, FormNote} from "@core/components/bp-form";
import {Alert} from "@core/components";
import VueFlatpickr from "vue-flatpickr-component"
import storeHelper from "@/store/helper";
import useLeadFunctions from "@core/comp-functions/LeadFunctions";
import {reactive} from "vue";
import {Dutch} from "flatpickr/dist/l10n/nl";
import {english} from "flatpickr/dist/l10n/default";
import {useStore} from "vuex";
import axios from "@/libs/axios";
import useIonicComps from "@core/IonComponents";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";

export default {
  components: {Alert, BRadio, BCheckBox, FormNote, Column, Row, FormGroup, IonItem, IModal, IonModal, VueFlatpickr},
  props:{
    modalOpen: Boolean,
    // Import only selected leads
    selectedLeads:{
      type: Array,
      default: ()=> []
    }
  },
  emits: ['exportInitiated', 'modalClose'],
  setup(props, {emit}){

    const store = useStore()
    const isDesktop = isPlatform('desktop')
    const{IonToast} = useIonicComps()
    const {t} = useI18n()
    const{userRole} = storeHelper()
    const route = useRoute()
    const{getCSVExportProfiles, leadsCSV_ExportColumns, leadsCSV_ExportProfiles} = useLeadFunctions()
    let newExport = reactive({
      filters:{
        from_date: '',
        to_date: '',
        include_appointments: false,
        include_archived: false,
        include_leads: true,
      },
      includedFields: [],
      export_profile: null,
    })
    const vueFlatPickerConfig = {
      altFormat: 'd/m/Y',
      altInput: true,
      time_24hr: true,
      locale: store.state.appConfig.lang === 'nl' ? Dutch: english,
    }
    const closeModal = async ()=>{
      await modalController.dismiss()
      emit('modalClose')
    }

    const startNewExport = async ()=>{
      const l = await loadingController.create({message: t('Please wait...')})
      await l.present()
      let data = Object.assign({}, newExport.filters)
      data.export_profile = newExport.export_profile;
      if(!newExport.export_profile || newExport.export_profile === 'custom'){
        data.export_fields = JSON.stringify(newExport.includedFields);
        data.export_profile = '';
      }
      data.leads_ids = JSON.stringify(props.selectedLeads)
      axios.put('/api/leads/csv_export/init_csv_export', data)
          .then( resp => {
            if(resp.data.success){
              let msg = t('Export initiated and will be processed in a while');
              if(route.name !== 'ExportLeadsCSV'){
                msg += ', '+t('you can check the status in the Export Leads menu');
              }
              IonToast({message: t(msg), position: 'top', color: 'primary', duration: 5000})
              emit('exportInitiated')
              closeModal()
              // Refresh this so any new profile created is now available to the user.
              getCSVExportProfiles();
            }
            else{
              IonToast({message: resp.data.message, position: 'top', color: 'danger', duration: 5000})
            }
          })
          .catch(error => {
            IonToast({message: t('Error')+': '+error.response.status+' '+error.response.statusText, position: 'top', color: 'danger', duration: 5000})
          })
          .then( () => l.dismiss())
    }

    const selectAllExportColumns = ()=>{
      newExport.includedFields.splice(0)
      for(let x in leadsCSV_ExportColumns) newExport.includedFields.push(x)
    }

    const F_getCSVExportProfiles = ()=>{
      if(!leadsCSV_ExportProfiles.value.length){
        getCSVExportProfiles(true)
      }
    }

    return{
      closeModal,
      F_getCSVExportProfiles,
      isDesktop,
      leadsCSV_ExportColumns,
      leadsCSV_ExportProfiles,
      newExport,
      selectAllExportColumns,
      startNewExport,
      userRole,
      vueFlatPickerConfig,
    }
  }
}
</script>
